<template>
  <div class="bookings pl-5">
    <v-row class="w-100">
      <v-col
        v-if="!($vuetify.breakpoint.mobile && paramID)"
        class="bookings-list"
        cols="12"
        :md="!$vuetify.breakpoint.mobile && paramID ? 6 : 12"
      >
        <div class="list-header">
          <div
            class="list-head d-flex justify-space-between align-center px-6 pt-5"
          >
            <span>
              <p class="gray--text mt-1">
                Discounted Guesthouse Bookings ({{ total }})
              </p>
            </span>
            <base-input
              :style="$vuetify.breakpoint.mobile ? { width: '100px' } : {}"
              placeholder="Search Bookings"
              @input="searchBooking"
              :rules="[]"
              v-model="searchWord"
            ></base-input>
          </div>
        </div>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :items="[{}, {}, {}, {}]"
            class="users-table"
            height="calc(100vh - 60px - 70px)"
            mobile-breakpoint="0"
            v-if="loading"
          >
            <template v-slot:item>
              <tr>
                <td v-for="(item, i) in headers" :key="i">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="bookings"
          item-key="_id"
          :item-class="
            (item) => {
              return paramID === item._id
                ? 'gray lighten-4 cursor-pointer'
                : 'cursor-pointer';
            }
          "
          class="users-table"
          height="calc(100vh - 60px - 70px)"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ 'items-per-page-options': [5, 15, 30, 50] }"
          :server-items-length="total"
          mobile-breakpoint="0"
          @click:row="viewItem"
          @update:page="fetchList"
          @update:items-per-page="fetchList"
          @update:sort-by="fetchList"
          @update:sort-desc="fetchList"
          fixed-header
        >
          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <div class="mr-2 mt-2">
                <img
                  v-if="
                    item.guests && item.guests.user && item.guests.user.picture
                  "
                  class="user-avatar"
                  :src="item.guests.user.picture.url"
                  alt=""
                />
                <img v-else class="user-avatar" :src="avatar" alt="" />
              </div>
              <div>
                <span class="d-block font-13 white-grey--text">{{
                  item.guests.user.name
                }}</span>
              </div>
            </div>
          </template>

          <!-- guests -->
          <template v-slot:item.guests="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              item.guests.total
            }}</span>
          </template>

          <!-- price -->
          <template v-slot:item.price="{ item }">
            <span class="d-block font-14 white-grey--text font-500"
              >$ {{ item.price }}</span
            >
          </template>

          <!-- status -->
          <template v-slot:item.status="{ item }">
            <span
              class="d-block font-13 font-500"
              :class="currentStatusColor(item.status)"
            >
              {{ currentStatusText(item.status) }}</span
            >
          </template>

          <!-- name -->
          <template v-slot:item.activity.name="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              item.activity.name
            }}</span>
          </template>

          <!-- stops -->
          <template v-slot:item.stops="{ item }">
            <span class="d-block font-13 white-grey--text ml-1">{{
              item.activity.stops
            }}</span>
          </template>

          <!-- duration -->
          <template v-slot:item.duration="{ item }">
            <span class="d-block font-13 white-grey--text ml-1">{{
              item.activity.duration
            }}</span>
          </template>

          <!-- created at -->
          <template v-slot:item.createdAt="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              $moment(item.createdAt).format("LLL")
            }}</span>
          </template>

          <!-- location -->
          <template v-slot:item.location="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              item.activity.location.address
            }}</span>
          </template>

          <!-- location -->
          <template v-slot:item.location.from="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              item.activity.location.from
            }}</span>
          </template>
        </v-data-table>
      </v-col>

      <!-- Details -->
      <v-col
        cols="12"
        :class="
          $vuetify.breakpoint.mobile
            ? 'maint-item pa-0 pt-10'
            : 'main-item pa-10 pr-5'
        "
        :md="6"
        v-if="paramID"
        :style="
          $vuetify.breakpoint.mobile ? {} : { borderLeft: '1px solid black' }
        "
      >
        <div
          class="cursor-pointer"
          :style="
            $vuetify.breakpoint.mobile
              ? {
                  position: 'absolute',
                  right: '10px',
                  top: '15px',
                  zIndex: '5'
                }
              : {
                  position: 'absolute',
                  left: 'calc(50% - 24px)'
                }
          "
          @click="collapse"
        >
          <v-icon size="40" color="black">mdi-progress-close</v-icon>
        </div>
        <div class="item-header">
          <div class="pt-3">
            <div class="">
              <img
                width="100%"
                class="main-img object-cover radius-5"
                v-if="itemID && itemID.details && itemID.details.coverImage"
                :src="itemID.details.coverImage.url"
              />
              <img
                width="100%"
                class="main-img w-100 object-cover radius-5"
                v-else
                src="http://ncs.cd.gov.mn/wp-content/themes/icetheme/assets/images/no-image-small.png"
              />
            </div>
          </div>
          <v-tabs
            v-model="selectedTab"
            centered
            active-class="secondary--text"
            color="secondary"
            background-color="transparent"
          >
            <v-tab><span class="font-12">Details</span></v-tab>
            <v-tab><span class="font-12">Customer</span></v-tab>
            <v-tab><span class="font-12">Guesthouse</span></v-tab>
            <v-tab><span class="font-12">Activity</span></v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="selectedTab" class="tabs-items mt-2">
          <v-tab-item
            ><details-section
              class="tab-item"
              v-if="itemID && itemID.customer && itemID.details"
              :item="itemID"
              :room="selectedRoom"
              @update="reloadData"
            />
            <p v-else class="text-center">No Booking Details</p>
          </v-tab-item>
          <v-tab-item>
            <user-details
              v-if="user"
              class="tab-item"
              :item="user"
              :viewOnly="true"
            />
            <p v-else class="text-center">No User Details</p>
          </v-tab-item>
          <v-tab-item>
            <guesthouse
              v-if="guesthouse"
              class="tab-item"
              :item="guesthouse"
              :viewOnly="true"
            />
            <p v-else class="text-center">No User Details</p>
          </v-tab-item>
          <v-tab-item>
            <activity-details
              v-if="activity"
              class="tab-item"
              :item="activity"
              :viewOnly="true"
            />
            <p v-else class="text-center">No Activity Details</p>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DetailsSection from "./components/Details/Details.vue";
import UserDetails from "@/components/User/UserDetails.vue";
import Guesthouse from "@/components/guesthouses/ItemBody";
import ActivityDetails from "@/components/discounted-guesthouses/ItemBody.vue";

const SortEnum = {
  NAMEASC: 1,
  NAMEDESC: 2,
  PRICEASC: 3,
  PRICEDESC: 4,
  GUESTSASC: 5,
  GUESTSDESC: 6,
  LOCATIONASC: 7,
  LOCATIONDESC: 8,
  LOCATIONFROMASC: 9,
  LOCATIONFROMDESC: 10,
  STOPSASC: 11,
  STOPSDESC: 12,
  ACTIVITYNAMEASC: 13,
  ACTIVITYNAMEDESC: 14,
  DURATIONASC: 15,
  DURATIONDESC: 16,
  STATUSASC: 17,
  STATUSDESC: 18,
  CREATEDATASC: 19,
  CREATEDATDESC: 20,

  init: function (sortBy, sortDesc) {
    const descSuffix = sortDesc ? "DESC" : "ASC";
    return this[
      `${sortBy
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .toUpperCase()}${descSuffix}`
    ];
  }
};
export default {
  components: {
    DetailsSection,
    UserDetails,
    Guesthouse,
    ActivityDetails
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    paramID: "",
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Guests", value: "guests" },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Activity Name", value: "activity.name" },
      { text: "Duration", value: "duration" },
      { text: "Stops", value: "stops" },
      { text: "Location", value: "location", width: "250px" },
      { text: "Created At", value: "createdAt", width: "250px" },
      { text: "Location From", value: "location.from", width: "150px" }
    ],
    bookings: [],
    itemID: {
      data: { coverImage: { url: "" } },
      details: { needToKnow: { bring: [], wear: [], expect: [] } }
    },
    markers: [],
    hosts: [],
    total: 0,
    keyUpdated: false,
    selectedTab: 0,
    page: 1,
    sortBy: "createdAt",
    sortDesc: true,
    itemsPerPage: 15,
    searchWord: undefined,
    guesthouse: undefined,
    activity: undefined,
    selectedRoom: undefined,
    user: undefined,
    loading: true
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    viewItem(item) {
      this.$router.push("/discount-experiences/" + item._id);
    },
    currentStatusColor(status) {
      if (status == 0) return "yellow--text";
      if (status == 1) return "blue--text";
      if (status == 2) return "orange";
      if (status == 3) return "green--text";
      if (status == 4) return "red--text";
      if (status == 5) return "gray--text";
    },
    currentStatusText(status) {
      if (status == 0) return "Pending";
      if (status == 1) return "Accepted";
      if (status == 2) return "Request confirmed";
      if (status == 3) return "Paid";
      if (status == 4) return "Cancelled";
      if (status == 5) return "Saved for AI";
    },
    async searchBooking(searchWord) {
      this.bookings = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.bookings.length > 0) {
          this.paramID = this.bookings[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async reloadData() {
      await this.fetchList();
      await this.fetchItemByID();
    },
    async fetchList() {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      const search =
        this.searchWord && this.searchWord.length > 0
          ? `&searchWord=${this.searchWord}`
          : "";
      const sort = this.sortBy
        ? `&sort=${SortEnum.init(this.sortBy, this.sortDesc)}`
        : "";
      var pageSize = this.itemsPerPage;
      if (this.itemsPerPage == -1) {
        pageSize = "";
      }
      let { data } = await this.$axios.get(
        `activityInstance/all/discounted-activity?pageSize=${pageSize}&pageNumber=${this.page}${sort}${search}`
      );
      if (data) {
        this.bookings = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchUser(userID) {
      if (userID) {
        let { data } = await this.$axios.get(`/users/${userID}`);

        if (data) {
          this.user = data.data;
        }
      } else {
        this.user = undefined;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `/activityInstance/all/discounted-activity/${this.paramID}`
        );
        if (data) {
          this.itemID = data.data;
          if (this.itemID.details) {
            if (this.itemID.details.attraction) {
              let guesthouse = await this.$axios.get(
                `guest-house/attraction/${this.itemID.details.attraction._id}`
              );
              if (guesthouse) {
                this.guesthouse = guesthouse.data;
                if (this.itemID.details.rooms.length > 0) {
                  let roomIndex = guesthouse.data.data.rooms.findIndex(
                    (room) => room._id === this.itemID.details.rooms[0]._id
                  );
                  this.selectedRoom = {
                    data: {
                      rooms: [
                        {
                          ...guesthouse.data.data.rooms[roomIndex],
                          roomNumber: roomIndex + 1
                        }
                      ]
                    }
                  };
                }
              }
            }
            if (this.itemID.details.activity) {
              let { data } = await this.$axios.get(
                `/discounted-activity/${this.itemID.details.activity}`
              );
              if (data) {
                this.activity = data;
              }
            }
            if (this.itemID.customer) {
              this.fetchUser(this.itemID.customer._id);
            }
            this.keyUpdated = !this.keyUpdated;
          }
        }
      }
    },
    collapse() {
      this.$router.push("/discount-experiences");
      this.paramID = undefined;
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    this.reloadData();
  }
};
</script>

<style lang="scss" scoped>
.bookings {
  background: white;
  height: 100vh;
  overflow: hidden;
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .list-header {
    height: 70px;
  }
  .users-table {
    overflow: auto;
  }
  .main-img {
    height: 250px;
  }
  .item-header {
    height: 320px;
    overflow: hidden;
  }
  .tabs-items {
    overflow: hidden;
  }
  .tab-item {
    height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
