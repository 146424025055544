<template>
  <div class="details-text">
    <div class="">
      <!-- text details -->
      <div class="main-title d-flex align-center justify-space-between">
        <div>
          <span
            class="main-title d-block my-2 black--text transition-3s font-16 font-500"
            >{{ item.details.address }}</span
          >
        </div>
      </div>
      <v-row class="mb-2">
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Date</span
          >
          <span
            class="error--text font-500 font-13 d-inline-block"
            v-if="item.details.needToKnow.date"
            >{{ item.details.needToKnow.date.slice(0, 10) }}</span
          >
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Total Stops</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.details.needToKnow.stops
          }}</span>
        </v-col>
        <v-col
          v-if="item.details.needToKnow.transaction"
          cols="12"
          md="6"
          class="py-1"
        >
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Transaction</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.details.needToKnow.transaction
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Experience GUESTS:</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.customer.family ? "Family - " : ""
          }}</span>
          <span class="error--text font-500 font-13 d-inline-block"
            >{{ item.customer.guests }} people</span
          >
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Time</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.customer.time
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Experience type:</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.customer.category[$root.language]
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Budget</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.customer.amount
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Payment method</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            getPaymentMethod(item.customer.paymentMethod)
          }}</span>
        </v-col>

        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Experience date:</span
          >
          <span class="error--text font-500 font-13 d-inline-block">{{
            item.customer.date.slice(0, 10)
          }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  methods: {
    getPaymentMethod(paymentMethodId) {
      if (paymentMethodId === 0) {
        return "Online Payment";
      } else {
        return "Cash United";
      }
    }
  },
  async created() {}
};
</script>

<style>
.details-text {
  overflow: hidden;
}
</style>
