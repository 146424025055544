<template>
  <div>
    <v-row>
      <v-col cols="12">
        <details-text :item="item"></details-text>
        <base-select
          v-if="item.customer"
          style="max-width: 240px"
          v-model="item.customer.bookingStatus"
          placeholder="state"
          @input="changeStatusHandler"
          :items="status"
          :rules="[]"
        ></base-select>
        <room v-if="room" :item="room"></room>
      </v-col>
    </v-row>
  </div>
</template>
z
<script>
import DetailsText from "./components/Text";
import Room from "@/components/guesthouses/details/Steps.vue";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    room: {
      type: Object,
      default: undefined
    }
  },
  components: {
    DetailsText,
    Room
  },
  data: () => ({
    status: [
      { title: "Pending", value: 0 },
      { title: "Accepted", value: 1 },
      // { title: "Request confirmed", value: 2 },
      { title: "Paid", value: 3 },
      { title: "Cancelled", value: 4 }
    ]
  }),
  methods: {
    async changeStatusHandler(value) {
      const res = await this.$axios.put(
        `/activityInstance/status/${this.item.details._id}`,
        {
          status: value.value
        }
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "updated successfully",
          color: "success"
        });
        this.$emit("update");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    }
  }
};
</script>

<style></style>
